<template>
  <div class="times-tag" v-bind:class="getStatusClass(status)">
    <p>{{ parseName(status) }}</p>
  </div>
</template>
<script>
export default {
  props: {
    status: { type: String, require: true },
  },
  methods: {
    parseName(status) {
      switch(status) {
        case 'paid_out':
          return 'Pago';
        case 'pending':
          return 'Pendente';
        case 'expired':
          return 'Expirado';
        case 'partial_paid_out':
          return 'Parc. pago';
        case 'disallowed':
          return 'Glosado';
        case 'on_appeal':
          return'Em recurso';
        default:
          return status
      }
    },
    getStatusClass(status) {
      let key = '';
      switch (status) {
        case 'paid_out':
        case 'Pago':
          key = 'paid';
          break;
        case 'expired':
        case 'Atrasado':
          key = 'overdue';
          break;
        case 'pending':
          key = 'pending';
          break;
        case 'partial_paid_out':
        case 'Pago parcialmente':
          key = 'partial_payment';
          break;
        case 'disallowed':
        case 'Glosado':
          key = 'glosado';
          break;
        case 'on_appeal':
        case 'Em recurso':
          key = 'on_appeal';
          break;
      }
      return { [key]: true };
    }
  }


}
</script>
<style lang="scss" scoped>
  .times-tag {
    padding: 4px 16px;
    border-radius: 50px;
    background-color: #d9dff2;
    color: var(--type-active) !important;
    width: fit-content;
    font-family: 'Nunito Sans';
    font-weight: 600;
    white-space: nowrap;
    font-size: 12px !important;

    &.paid {
      background: #DBFFF0;
    }

    &.overdue {
      background: #FED2CE;
    }

    &.pending {
      background: #FFEBCC;
    }

    &.installments {
      background: #DBE7FF;
    }

    &.partial_payment {
      background: #E7FFDB;
    }

    &.glosado {
      background: #FED2CE;
    }

    &.on_appeal {
      background: #E2CCFF;
    }

  }

  @media print {
    p {
      font-size: 10px !important;
    }
  }
</style>
