<template>
  <div>
    <b-table-simple class="table">
      <thead>
        <tr>
          <td class="td-head">Data</td>
          <td class="td-head">Situação</td>
          <td class="td-head">Item</td>
          <td class="td-head">Convênio</td>
          <td class="td-head">Favorecido</td>
          <td class="td-head">Recebimento/Guia</td>
          <td class="td-head">Valor</td>
          <td class="td-head"></td>
        </tr>
      </thead>
      <tbody v-if="!loading">
        <tr v-for="historyItem in historyItems" :key="historyItem.id">
          <td>{{ parseDate(historyItem.date) }}</td>
          <td>
            <BillStatus :status="getItemSituation(historyItem)"/>
          </td>
          <td>
            <span class="type">{{ parseItemType(historyItem?.item?.type) }} | </span> 
            <span :class="{'deleted-item':historyItem?.item?.deleted_at}">
              {{ historyItem.item?.name }}
            </span>
          </td>
          <td>{{ historyItem.health_plan?.fantasy_name }}</td>
          <td>{{ parseProfessionalName(historyItem) }}</td>
          <td>
            <div v-for="method in getPaymentMethods(historyItem)" :key="method">
              {{ method }}
            </div>
          </td>
          <td>{{ parseNumberToMoney(historyItem.value) }}</td>
          <td>
            <div class="actions">
              <Icon tooltip="Editar e ver detalhes">
                <EyeFill class="icon" @click="showModal(historyItem)" />
              </Icon>
            </div>
          </td>
        </tr>
      </tbody>
    </b-table-simple>

    <div v-if="(!loading && !Object.keys(historyItems)?.length)" class="empty">
      Nenhum histórico por aqui ainda
    </div>

    <div v-if="loading">
      <div class="skeleton"><b-skeleton animation="fade" width="100%"></b-skeleton></div>
      <div class="skeleton"><b-skeleton animation="fade" width="100%"></b-skeleton></div>
      <div class="skeleton"><b-skeleton animation="fade" width="100%"></b-skeleton></div>
      <div class="skeleton"><b-skeleton animation="fade" width="100%"></b-skeleton></div>
      <div class="skeleton"><b-skeleton animation="fade" width="100%"></b-skeleton></div>
    </div>
  </div>
</template>
<script>
import { parseType, parseItemType } from '@/utils/itemHelper'
import { parseNumberToMoney } from '@/utils/moneyHelper'
import Icon from '@/components/General/Icon'
import EyeFill from '@/assets/icons/eye-fill.svg'
import BillStatus from '@/components/Financial/BillStatus'

export default {
  components: { Icon, EyeFill, BillStatus },
  props: {
    historyItems: [Object, Array],
    loading: Boolean,
    showModal: Function,
  },
  methods: {
    parseType,
    parseItemType,
    parseNumberToMoney,
    parseDate(datetime) {
      return datetime ? this.moment(datetime).format('DD/MM/YYYY') : ''
    },
    parseProfessionalName(historyItem) {
      let name = '';

      if (historyItem?.type !== 'susGuideItem') {
        name = historyItem.beneficiary?.name
      } else {
        if(typeof historyItem.professional === 'object'){
          name = historyItem.professional?.name
        } else {
          name = historyItem.professional;
        }
      }
      
      if (!name) return '-'
      
      const parts = name.split(' ')
      
      if (parts.length < 2) return parts[0]
      
      return `${parts[0]} ${parts[parts.length - 1]}`
    },
    getItemSituation(historyItem) {
      const statuses = [];
      if (historyItem?.type === 'bill') {
        historyItem.payments
          .map(payment => payment.installments.map(installment => (statuses.push(installment.status))))
      } else if (historyItem?.type === 'tissGuideItem') {
        statuses.push(historyItem.status)
        return statuses[0] || 'Pendente'
      }
      if (statuses.every(status => status === 'pending')) {
        return 'pending'
      } else if (statuses.every(status => status === 'paid_out')) {
        return 'paid_out'
      } else if (statuses.some(status => status === 'overdue')) {
        return 'overdue'
      } else if (statuses.some(status => status === 'pending') && statuses.some(status => status === 'paid_out')) {
        return 'partial_paid_out'
      } else {
        return 'Pendente'
      }
    },
    getPaymentMethods(historyItem) {
      if (historyItem?.type === 'bill') {
        return historyItem.payments.map((payment, i) => `#${i+1} | ${payment.payment_method?.name ?? ''} ${payment.installments?.length}x`)
      } else if (historyItem?.type === 'tissGuideItem') {
        return [historyItem.guide_type, `Nº ${historyItem.guide_number}`]
      } else if (historyItem?.type === 'susGuideItem') {
        return [historyItem.guide_type, `Nº ${historyItem.guide_number}`]
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.table {
  margin: 20px 0;

  thead {
    td {

      &:first-child {
        border-radius: 8px 0 0 0;
      }
      &:last-child {
        border-radius: 0 8px 0 0;
      }
    }
  }

  tr, td {
    padding: 10px 6px;
    font-size: 0.8rem;
    vertical-align: middle;
    color: var(--type-active);
  }

  .td-head {
    color: var(--dark-blue);
    font-weight: 700;
    font-size: 14px;
    border: none;
    background-color: var(--neutral-100);
  }

  .deleted-item {
    text-decoration: line-through;
  }

  .type {
    color: var(--blue-500);
    font-weight: 600;
  }

  .actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;

    .pointer {
      cursor: pointer;
    }

    .icon {
      width: 20px;
      fill: var(--blue-500);
      cursor: pointer;
    }
  }
}

.empty {
  width: 100%;
  text-align: center;
  padding: 10px;
  font-size: 12px;
  color: var(--type-active);
}

.skeleton {
  width: 100%;
  padding: 6px;
}
</style>
