<template>
  <div class="account-history-wrapper">
    <div>
      <div class="history-title">Histórico do paciente</div>
    </div>
    <AccountHistoryTable
      :loading="loading"
      :historyItems="historyItems"
      :showModal="showModal"
    />
    <div class="pagination-position">
      <b-pagination
        :value="page"
        :total-rows="count"
        :per-page="limit"
        first-number
        last-number
        size="sm"
        @input="onChangePage"
      />
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import AccountHistoryTable from './AccountHistoryTable'

export default {
  components: { AccountHistoryTable },
  props: {
    patient: Object,
    showModal: Function,
  },
  computed: {
    ...mapState('historyItems', ['historyItems', 'page', 'count', 'limit', 'loading'])
  },
  mounted() {
    this.getHistoryItemsFromPatient(this.patient.id)
  },
  methods: {
    ...mapActions('historyItems', ['getHistoryItemsFromPatient', 'changePage']),
    onChangePage(page) {
      this.changePage(page)
      this.getHistoryItemsFromPatient(this.patient.id)
    }
  }
}
</script>
<style lang="scss" scoped>
.account-history-wrapper {
  padding: 1.5rem 1rem;
  border-radius: 8px;
  border: 1px solid var(--neutral-300);
  margin: 30px 0;
  .history-title {
    font-weight: 700;
    font-size: 16px;
    color: var(--dark-blue);
  }
  .pagination-position {
    padding: 0;
  }
}
</style>
